import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar"
import Slider from "react-slick"
import Layout from "components/layout"
import SEO from "components/seo"
import MarkdownViewer from "@components/MarkdownViewer"
import { Section, Container } from "../components/Layouts"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Button from "../components/Buttons/Button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { MarketingAnalysisOMS } from "../components/Forms"

function EmailBlast({ data, location }) {
  const { href, pathname } = location
  const post = data.allEmailBlastsJson.nodes[0]
  let stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(<FontAwesomeIcon icon={faStar} key={i} />)
  }

  const anchorLink = "#cta-form/"
  const page = {
    metaTitle: post.metaTitle,
    metaDescription: post.metaDescription,
    heading: post.heading,
    heroSection: {
      image: "NUVO/V3/oms-marketing-hero"
    }
  }

  const cardsSection = {
    content:
      "### Why Practices Love Nuvolum\n\n Nuvolum provides beyond just better marketing. We breathe life into your brand and create sustainable, measurable growth. Request a marketing analysis to discover how Nuvolum takes you where you want to go.",
    youtube: "wdUleyuf3jc",
    image: "NUVO/V3/win-with-nuvolum-usa-youtube-thumb",
    cards: [
      {
        name: "Russell Lieblick, DMD",
        image: "NUVO/V3/about-doctor-lieblick",
        practice: "Beacon Oral & Maxillofacial Surgeons",
        title: "Increase Case Acceptance",
        text: "My personal case acceptance increased 6% for implant (80% to 85%) and other surgery (86% to 92%)... That would mean an ROI of about 10x! And my partner is experiencing the same increase."
      },
      {
        name: "Robert Beech, DDS",
        image: "NUVO/V3/about-doctor-beech",
        practice: "Beech & Reid Oral & Dental Implant Surgery",
        title: "Dominate Your Market",
        text: "The exposure is great, definitely. They ramped up our website, which we get positive comments on all the time with people saying the videos and testimonials are helpful. That's really valuable."
      },
      {
        name: "Michael Harris, DDS, MD",
        image: "NUVO/V3/about-doctor-harris",
        practice: "Utah Surgical Arts",
        title: "Superior Experience",
        text: "Nuvolum has exceeded our expectations. We’re constantly communicating with them, and they deliver... It’s been a great experience. We love Nuvolum."
      }
    ],
    buttonText: "Let's Talk Success"
  }

  const marketingSection = {
    content:
      "### Success Starts Here \n\n Drive more referrals, increase case acceptance, increase online visibility, expand to new offices, and more. Wherever your goals are, let Nuvolum take you there.",
    steps: [
      {
        top: "Request a Marketing Analysis",
        bottom: "Tell us about your brand."
      },
      {
        top: "Get Your Custom Strategy",
        bottom: "We'll show you how to win your market."
      },
      {
        top: "Win With Nuvolum",
        bottom: "Let's get you where you want to be."
      }
    ],
    buttonText: "Request a Marketing Analysis"
  }

  return (
    <Layout
      language="en"
      path={pathname}
      className="marketing-blog side-by-side">
      <SEO title={page.metaTitle} description={page.metaDescription} />

      <div className="ortho-marketing-page">
        <Section className="hero__heading">
          <Container large className="has-text-centered">
            <MarkdownViewer priority="1" markdown={page.heading} />
          </Container>
        </Section>

        <Section>
          <Container>
            <div className="eb__videos">
              {post.videos.list.map(item => (
                <React.Fragment key={item.videoUrl}>
                  <TheaterVideo
                    videoUrl={item.videoUrl}
                    language="en"
                    playing
                    controls
                    overVideo
                    buttonClass="contained">
                    <NuvoImage
                      cloudName="nuvolum"
                      publicId={item.image}
                      responsive
                      className="w-100"
                      width="auto"
                      useAR
                    />
                  </TheaterVideo>
                </React.Fragment>
              ))}
            </div>
          </Container>
        </Section>

        <Section colorBack className="relative">
          <Container>
            <div className="">
              <div>
                <MarketingAnalysisOMS
                  id="cta-form"
                  origin={href}
                  phone="(415) 304-8425"
                />
              </div>
            </div>
          </Container>
        </Section>

        <Section className="color-back">
          <Container large>
            <MarkdownViewer markdown={cardsSection.content} />
          </Container>
          {cardsSection.cards[0] && (
            <Section>
              <Container full>
                {cardsSection.cards.length > 3 ? (
                  <Slider {...settings}>
                    {cardsSection.cards.map((card, i) => {
                      return (
                        <div className="card card-slider-height" key={i}>
                          <div className="card-content">
                            <div className="media">
                              <div className="media-left">
                                <ImageMeta
                                  cloudName="nuvolum"
                                  publicId={card.image}
                                  responsive
                                  width="auto"
                                />
                              </div>
                              <div className="media-content">
                                <p className="title">{card.name}</p>
                                <p className="subtitle">{card.practice}</p>
                              </div>
                            </div>
                            <div className="card-rating">
                              <div className="stars">{stars}</div>
                              <p>{card.title}</p>
                            </div>

                            <div className="content">
                              <span>&ldquo;</span>
                              <p>{card.text}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                ) : (
                  <div className="columns is-desktop">
                    {cardsSection.cards.map((card, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="card column">
                            <div className="card-content">
                              <div className="media">
                                <div className="media-left">
                                  <ImageMeta
                                    cloudName="nuvolum"
                                    publicId={card.image}
                                    responsive
                                    width="auto"
                                  />{" "}
                                </div>
                                <div className="media-content">
                                  <p className="title">{card.name}</p>
                                  <p className="subtitle">{card.practice}</p>
                                </div>
                              </div>
                              <div className="card-rating">
                                <div className="stars">{stars}</div>
                                <p>{card.title}</p>
                              </div>

                              <div className="content">
                                <span>&ldquo;</span>
                                <p>{card.text}</p>
                              </div>
                            </div>
                          </div>
                          {i < 2 && <div className="column is-1">&nbsp;</div>}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              </Container>
            </Section>
          )}
          <Container>
            <div className="columns is-centered form">
              {/* <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {cardsSection.buttonText}{" "}
              </button> */}
              <Button
                className="mx-auto"
                anchor
                rounded
                green
                href={anchorLink}
                buttonText={cardsSection.buttonText}
              />
            </div>
          </Container>
        </Section>

        <Section className="color-back work-together">
          <Container large>
            <div className="has-text-centered">
              <MarkdownViewer markdown={marketingSection.content} />
            </div>
            <Section>
              <div className="columns marketing-section">
                {marketingSection.steps.map((step, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="column marketing-section__steps">
                        <div className="circle">
                          <h6 id={`num-${i + 1}`}>{i + 1}</h6>
                        </div>
                        <h5>{step.top}</h5>
                        <p>{step.bottom}</p>
                      </div>
                      {i < 2 && (
                        <>
                          <div className="arrow is-hidden-mobile">
                            <span>&#10230;</span>
                          </div>
                          <div className="column is-1 is-hidden-tablet">
                            &nbsp;
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </Section>
            <div className="columns is-centered form">
              <Button
                className="mx-auto"
                anchor
                rounded
                buttonText={marketingSection.buttonText}
                href={anchorLink}
              />
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default EmailBlast

export const singleEmailBlastPageQuery = graphql`
  query singleEmailBlast($title: String!) {
    allEmailBlastsJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        metaTitle
        metaDescription
        videos {
          className
          list {
            videoUrl
            image
          }
        }
      }
    }
  }
`
